import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useFilters } from "../../hooks/use-filters"
import { useLazyLoadingOffset } from "../../hooks/use-lazy-loading-offset"
import { useUser } from "../../hooks/use-user"
import { compactDateWithSeconds } from "../../services/date"
import { TxLogItem } from "../../types"
import LogItem from "./log-item"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
  accountId: string | null
}

const LogFeed: FunctionComponent<Props> = ({ accountId }) => {
  const [items, setItems] = useState<TxLogItem[]>([])
  const { filters } = useFilters()
  const { user } = useUser()

  const parseCommand = (tx: string) => {
    const j = JSON.parse(tx)

    if ("donor" in j) {
      return "add_funds"
    } else {
      const d = JSON.parse(j["data"])
      if ("kind" in d) {
        return d["kind"]
      } else {
        return ""
      }
    }
  }

  const fetchPage = useCallback(
    async (offset: string) => {
      /*
      const urlSearchParams = new URLSearchParams(location.search)
      if (urlSearchParams.has("user")) {
        console.log(urlSearchParams.get("user"))
      }*/

      if (user || accountId) {
        let url =
          "/api/v2/txs/" +
          (accountId ? accountId : user?.id) +
          "?count=30" +
          (offset.length ? "&offset=" + offset : "")
        const response = await Promise.resolve(
          fetch(url)
            .then(res => res.json())
            .catch(error => {
              console.log("json parsing error occured ", error)
              return [[], ""]
            })
        )
        if (!response) return [[], ""]

        //console.log(response)
        let newItems: TxLogItem[] = []
        let newOffset = ""
        for (var i = 0; i < response.length; i++) {
          let item = JSON.parse(response[i])
          item.command = parseCommand(item.tx)
          item.tx = item.tx.replaceAll('\\\\"', '"').replaceAll('\\"', '"')
          item.expanded = false
          newItems.push(item)
          newOffset = item.ts + "." + item.seq
        }

        console.log("newOffset " + newOffset)

        //console.log(newItems)
        return [newItems, newOffset]
      } else return [[], ""]
    },
    [user, filters]
  )

  const add = useCallback(
    (newItems: TxLogItem[], offset: string) => {
      //console.log(newItems)
      if (offset.length == 0 && items.length > 0) {
        console.log("clearing items")
        setItems([])
      }
      if (newItems && newItems.length) {
        //setItems(items => items.concat(newItems))
        for (var i = 0; i < newItems.length; i++) {
          if (
            items.length &&
            newItems[i].ts == items[items.length - 1].ts &&
            newItems[i].tx == items[items.length - 1].tx
          ) {
            newItems.shift()
          } else break
        }
        setItems(items => items.concat(newItems))
      }
      // TODO: trigger single rererender
    },
    [items]
  )

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoadingOffset<TxLogItem>(items.length ? items : [], {
      fetchPage,
      add,
    })

  /*const getContent = useCallback((items: Channel[]) => {
        const itemsCount = 3
        const content: Channel[] = items
        return content
      }, [])
    
      const content = useMemo(() => getContent(items), [items.length, filters])*/

  useEffect(() => {
    //console.log("Feed: filters changed " + filters.channel_ids)
    setItems([])
    resetLoader()
  }, [filters])

  const expand = (id: string) => {
    let e = document.getElementById(id)
    let u = document.getElementById(id + ">")
    let d = document.getElementById(id + "<")
    if (e && u && d) {
      if (e.style.display === "block") {
        e.style.display = "none"
        d.style.display = "none"
        u.style.display = "block"
      } else {
        e.style.display = "block"
        u.style.display = "none"
        d.style.display = "block"
      }
    }
  }

  return (
    <div>
      <table style={{ width: "100%", fontFamily: "Roboto" }}>
        <tr>
          <td style={{ minWidth: "110px" }}>time</td>
          <td>amount</td>
          <td>operation</td>
          <td>balance</td>
          <td></td>
        </tr>
        <tr>
          <td colSpan={5}> </td>
        </tr>
        {items.map(entry => (
          <>
            <tr
              onClick={() => {
                expand(entry.ts + "." + entry.seq)
              }}
              className="tx-row"
            >
              <td>{compactDateWithSeconds(entry.ts)}</td>
              <td>{entry.amount}</td>
              <td>{entry.command}</td>
              <td>{entry.balance}</td>
              <td className="table-cell show-more">
                <FontAwesomeIcon
                  icon={faChevronUp}
                  id={entry.ts + "." + entry.seq + "<"}
                  style={{ display: "none" }}
                />
                <FontAwesomeIcon
                  icon={faChevronDown}
                  id={entry.ts + "." + entry.seq + ">"}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="tx">
                <div
                  id={entry.ts + "." + entry.seq}
                  style={{ display: "none" }}
                >
                  {entry.tx}
                </div>
              </td>
            </tr>
          </>
        ))}
      </table>
      <style jsx>
        {`
          .tx {
            font-family: monospace;
            word-break: break-all;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          .show-more {
            margin-left: 10px;
            margin-right: 10px;
          }

          .tx-row {
          }
          .tx-row:hover {
            cursor: pointer;
          }

          .hidden {
            display: none;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          li:not(:first-child) {
            /*margin-top: 1em;*/
          }
          li {
            margin-bottom: 5px;
          }
          .loader {
            padding: 5em 1em;
            text-align: center;
          }
          span {
            margin-left: 0.5em;
          }
          p {
            margin-top: 100px;
            padding: 20px;
            text-align: center;
          }
        `}
      </style>
    </div>
  )
}

export default LogFeed
