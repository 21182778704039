import React, { FunctionComponent } from "react"
import { Helmet as Head } from "react-helmet"
import MinimalSortFilter from "../components/layout/minimal-sort-filter"
import SupportNetwork from "../components/layout/support-network"
import SupportersFeed from "../components/layout/supporters-feed"
import ResponsiveLayout from "../components/layout/responsive-layout"
import Notice from "../components/widgets/notice"
import { useUser } from "../hooks/use-user"
import { useMedia } from "../hooks/use-media"
import LogFeed from "../components/layout/log-feed"

const LogPage: FunctionComponent = ({ location }) => {
  const parts = location.pathname.split("/")
  console.log("parts.length = " + parts.length + " " + parts[1])
  //const initialChannelId = parts[2]

  const { user } = useUser()
  const { numColumns } = useMedia()
  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ResponsiveLayout>
        {{
          content: (
            <>
              <Notice id="log_notice" submit="Got it">
                <p>This page shows your most recent transactions.</p>
              </Notice>
              <LogFeed accountId={parts.length > 2 ? parts[2] : null} />
            </>
          ),
          sidebar: null,
        }}
      </ResponsiveLayout>
    </>
  )
}
export default LogPage
